const minWidth = 60;
import api from "@api";
import { isFilter, isArray, mergeJSON, dateFormat } from "@utils/utils";
import headerEditMixin from './headerEdit.js';

export default {
  mixins: [headerEditMixin],
  props: {
    // id
    id: {
      type: String,
      default: 'id'
    },
    // 分页控制
    pagination: {
        type: Boolean,
        default: true
    },
    // border
    border: {
        type: Boolean,
        default: true
    },
    // 接口
    services: {
        type: String
    },
    exts: {
        type: Array,
        default: function() {
            return [];
        }
    },
    /**
     * 配置列数组
     *  [{
     *      label: '操作', 必填    列名称
     *      value: 'key1', 必填    数据中对应key
     *      width: '60',   非必填   单位px
     *      slot: 'slot1', 非必填  可选自定义插槽
     *      valueFun: function(row){},  非必填   重写函数，参数为当前行数据
     *      type: 'date|operate',  非必填   可选值： 'date' 'operate'
     *      format: 'yyyy-MM-dd H:mm'  非必填 type === date 时重写时间格式，可通过format自定义格式
     *      sortable: 'true', 非必填    是否可以排序当前列
     *      sortMethodFun // 参考 https://element.eleme.cn/#/zh-CN/component/table
     *      sortBy // 参考 https://element.eleme.cn/#/zh-CN/component/table
     *      showOverflowTooltip // 参考 https://element.eleme.cn/#/zh-CN/component/table
     *      btns: [{
     *              label: '详情',  // 按钮文字
     *              click: 'detailCallback', // 回掉函数， 参数为当前行数据
     *              conditionFun: function(row) {}  // return true|false  该按钮显示与否
     *          }]  非必填， type === 'operate' 时必填
     *  }]
     */
    tableColumn: {
        type: Array,
        default: function() {
            return [];
        }
    },
    dropColumn: {
        type: Array,
        default: function() {
            return [];
        }
    },
    importData: {
        type: Array, // 使用外部tableData数据，
        default: () => []
    },
    param: {
        type: Object,
        default: function() {
            return {};
        }
    },
    showEmptyImg: {
        type: Boolean,
        default: true
    },
    dataFormatHandle: {
        type: Function
    },
    initQuery: {
        type: Boolean,
        default: true
    },
    headShowOverflowTip:{
      type: Boolean,
      default: true
    }
  },
  data() {
      return {
          loading: false,
          total: 0, // 总数据量
          pager: {
              pageNo: 1,
              pageSize: 200
          },
          dataSet: [],
          selectList: [],
          speciaVal: {}
      };
  },
  computed: {
      paginationInfo() {
          return {
              total: this.total,
              pager: this.pager,
              selected: this.selectList.length
          };
      },
      // 操作项留出宽度
      autoWith() {
          return function(options) {
              let width = minWidth;
              if (options && options.length > 0) {
                  width = options.length * 50;
                  return minWidth > width ? minWidth : width;
              }
              return 0;
          };
      },
      tableData() {
          return this.importData.length ? this.importData : this.dataSet;
      }
  },
  created() {
      this.initQuery && this.onQuery();
  },
  mounted() {
      this.columnDrop()
  },
  methods: {
    /**
     * @method 操作项按钮点击处理函数
     * @param {String} event
     * @param {Object} rowData
     */
    callBack(event, rowData) {
        event && this.$emit(event, rowData);
    },
    /**
     * @method 多选触发事件
     * @param {Array} selectList
     */
    selectionChange(selectList) {
        this.selectList = selectList || [];
        this.$emit("selection-change", selectList);
    },
    /**
     * @method 分页变化
     * @param {Object} data
     */
    $comSelectChange(data) {
        this.pager = Object.assign(this.pager, data);
        this.onQuery();
    },
    /**
     * @method 按钮显示条件
     */
    btnCondition(btn, scope) {
        if (btn.conditionFun) {
            return btn.conditionFun.call(this, scope.row);
        }
        return true;
    },
    /**
     * @method table单选方法
     * @param {String,Array} method params
     */
    setCurrentRow(row) {
        this.$refs.table.setCurrentRow(row);
    },
    /**
     * @method 格式化表格数据
     */
    formatFun(data, row) {
        const column = isFilter(this.tableColumn, item => {
            return item.value === row.property;
        });
        if (column["valueFun"]) {
            return column.valueFun.call(this, data);
        }
        if (column["rewrite"]) {
            return column.rewrite[data[row.property]];
        }
        if (column["type"] === "date") {
            let format = column["format"];
            return dateFormat(
                new Date(data[row.property]),
                format || "YYYY-MM-DD hh:mm"
            );
        }
        return data[row.property];
    },
    /**
     * @returns {Object}
     */
    getParam() {
        let { pagination } = this;
        if (pagination) {
            return mergeJSON(this.param, {
                page: {
                    pageNo: this.pager.pageNo,
                    pageSize: this.pager.pageSize
                }
            });
        }
        return this.param;
    },
    /**
     * @method 获取数据
     */
    onQuery() {
        return this.request();
    },
    setParam(param) {
        this.param = param;
    },
    clearParam() {
        this.param = {};
    },
    setPagerNumber(pagerNumber) {
        this.pagerNumber = pagerNumber;
    },
    getSelectList() {
        return this.selectList;
    },
    getFullData() {
        return {
            total: this.total,
            pager: this.pager,
            dataSet: this.dataSet,
            selectList: this.selectList
        };
    },
    /**
     * @method 获取table数据
     */
    async request() {
        if (!this.services) {
            return this.$emit("data-change",this.getFullData());
        }
        this.loading = true;
        const url = api.get(this.services),
            params = this.getParam();
        let result;
        try {
            result = await api.post({
                url: url,
                data: params
            });
        } catch (err) {
            this.loading = false;
        }
        this.loading = false;
        if (this.dataFormatHandle) {
            result = this.dataFormatHandle(result);
        } //表格数据格式化
        if (isArray(result)) {
            this.dataSet = result || [];
            this.total = result.length || 0;
        } else {
            this.dataSet = result["results"]
                ? result["results"]
                : result["data"] || [];
            result && (this.total = result["total"] || 0);
        }
        //处理表格数据
        this.$emit("data-change", this.getFullData());
    },
    //解决表格布局问题
    doLayout(){
        this.$refs.table && this.$refs.table.doLayout();
    },
    clearSelection(){
      this.$refs.table.clearSelection()
    }
  }
};
