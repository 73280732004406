
import Sortable from 'sortablejs';

export default {
  methods: {
    /**
    * @description 列宽拖拽
    */
    headerDragend(newWidth, oldWidth, column, event) {
      this.$emit('headerDragend', {newWidth, oldWidth, column, event})
    },
    /**
     * @description 列拖拽
    */
    columnDrop() {
      const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: async (evt) => {   
          const {newIndex, oldIndex, event} = evt;
          const dropColumn = JSON.parse(JSON.stringify(this.dropColumn));

          const oldItem = dropColumn[oldIndex];
          dropColumn.splice(oldIndex, 1);
          dropColumn.splice(newIndex, 0, oldItem);
          
          await this.$emit('headerDroped', dropColumn);
          
        }
      })
    },
  }
}